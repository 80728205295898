import { Link } from 'gatsby';
import { FiInfo } from 'react-icons/fi';
import { Counter, LikeButton } from "../../../../src/components/Complete";
import * as React from 'react';
export default {
  Link,
  FiInfo,
  Counter,
  LikeButton,
  React
};